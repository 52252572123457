import React, { useState, useRef, useEffect } from "react";
import "./Demo.scss";
import { story } from "../../content/story";
import { containsPhrase } from "../../helper/helper";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = new SpeechRecognition();

const Demo = () => {
  const [transcript, setTranscript] = useState("");
  const [currentPart, setCurrentPart] = useState(0);
  const [isListening, setIsListening] = useState(false);
  const [endReached, setEndReached] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const videoRef = useRef(null);
  const currentPartRef = useRef(currentPart);
  const navigate = useNavigate();

  useEffect(() => {
    // Detect if the user is on a mobile device
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
    setIsMobile(isMobileDevice);

    const videoElement = document.getElementById("videoElement");
    if (videoElement) {
      videoRef.current = videoElement;
    }
  }, []);

  useEffect(() => {
    if (isMobile) return;

    recognition.continuous = true;
    recognition.interimResults = true;

    recognition.onresult = (event) => {
      let interimTranscript = "";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcriptText = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          setTranscript((prev) => prev + transcriptText + " ");
          checkPartMatch(transcriptText);
        } else {
          interimTranscript += transcriptText;
        }
      }
    };

    recognition.onerror = (event) => {
      console.error(event.error);
    };
  }, [currentPart, isMobile]);

  const checkPartMatch = (text) => {
    if (containsPhrase(text, story[currentPart].leniency)) {
      playVideo();
    }
  };

  const nextButtonPressed = () => {
    setCurrentPart((prev) => {
      const newPart = prev + 1;
      if (newPart < story.length) {
        return newPart;
      } else {
        if (newPart === story.length) {
          setEndReached(true);
          return newPart;
        }
        return prev;
      }
    });
  };

  const prevButtonPressed = () => {
    setCurrentPart((prev) => {
      const newPart = prev - 1;
      if (newPart >= 0) {
        setEndReached(false);
        return newPart;
      } else {
        return prev;
      }
    });
  };

  const playVideo = () => {
    const video = videoRef.current;
    let isPlaying = false;

    if (video && story[currentPart]) {
      video.onplaying = function () {
        isPlaying = true;
      };

      video.onpause = function () {
        isPlaying = false;
      };

      const pauseVid = () => {
        if (!video.paused && isPlaying) {
          video.pause();
        }
      };

      const playVid = async () => {
        if (video.paused && !isPlaying) {
          return video.play();
        }
      };

      pauseVid();

      currentPartRef.current = currentPart;
      const { start, end } = story[currentPart].part;

      const stopPlayback = () => {
        if (video.currentTime >= end) {
          video.pause();
          video.currentTime = end;
          nextButtonPressed();
          video.removeEventListener("timeupdate", stopPlayback);
        }
      };

      video.currentTime = start;
      video.addEventListener("timeupdate", stopPlayback);
      playVid();

      return () => {
        video.removeEventListener("timeupdate", stopPlayback);
      };
    }
  };

  const toggleListening = () => {
    if (isListening) {
      recognition.stop();
    } else {
      recognition.start();
    }
    setIsListening(!isListening);
  };

  if (isMobile) {
    return (
      <div className="demoContainer">
        <nav className="navbar">
          <div className="logo" onClick={() => navigate("/")}>
            <img src="assets/img/logo.png" alt="" />
          </div>
        </nav>
        <div className="mobile-message">
          <p>
            This demo is not available on mobile devices. Please access it from
            a computer or download the app!
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="demoContainer">
      <Helmet>
        <title>Blive Demo</title>
        <meta
          name="description"
          content="Come and get a taste of Voice Activated Animation Stories"
        />
      </Helmet>
      <nav className="navbar">
        <div className="logo" onClick={() => navigate("/")}>
          <img src="assets/img/logo.png" alt="" />
        </div>
      </nav>
      <div className="main-content">
        <div className="video-section">
          <video
            ref={videoRef}
            src="/assets/MainStory.mp4"
            className="video-placeholder"
            playsInline
            type="video/mp4"
          >
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="text-section">
          <button
            className={`toggle-listening ${
              isListening ? "listening" : ""
            } bigger`}
            onClick={toggleListening}
          >
            <div className="icon">
              {isListening ? (
                <img src="/assets/mute.svg" alt="Mute" className="mute" />
              ) : (
                <img src="/assets/mic.svg" alt="Mic" className="mic" />
              )}
            </div>
            {isListening ? "Press to Stop" : "Press and Speak"}
          </button>
          <div className="text-container">
            {endReached ? (
              <p>
                The end! <br />
                To continue reading, please try our app!
              </p>
            ) : (
              <p>{story[currentPart].text}</p>
            )}
          </div>
          <button
            className={`toggle-listening ${
              isListening ? "listening" : ""
            } smaller`}
            onClick={toggleListening}
          >
            <div className="icon">
              {isListening ? (
                <img src="/assets/mute.svg" alt="Mute" className="mute" />
              ) : (
                <img src="/assets/mic.svg" alt="Mic" className="mic" />
              )}
            </div>
            {isListening ? "Press to Stop" : "Press to Speak"}
          </button>
        </div>
      </div>
      <div className="pagination">
        <button className="pagination-button back" onClick={prevButtonPressed}>
          {"< back"}
        </button>
        <button className="pagination-button next" onClick={nextButtonPressed}>
          {"next >"}
        </button>
      </div>
    </div>
  );
};

export default Demo;
