import React from "react";
import "./InteractiveStory.scss";
import { Flower } from "../../Icon";

export default function InteractiveStory() {
  return (
    <section id="interactive-story">
      <div className="container">
        <h2>How Our Interactive Story App Works</h2>
        <p>
        Discover an enchanting experience where your voice transforms into animated stories, making storytime both magical and educational. 
        </p>

        <div className="box-group">
          {strugglingContents.map((ct, i) => (
            <div key={i} className="box">
              <div className="d-flex justify-content-center align-items-center">
                <Flower />
                <h4>{ct.title}</h4>
              </div>
              <p className="text-center m-0" style={{ color: "#000" }}>
                {ct.text}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

const strugglingContents = [
  {
    title: "Choose a Story",
    text: "Select from a variety of enchanting tales designed for young minds.",
  },
  {
    title: "Speak a Phrase",
    text: "You speak a phrase, and our voice-to-animation technology brings it to life.",
  },
  {
    title: "Start Reading",
    text: "Read aloud and watch as you and your child experience the magical adventure.",
  },
  {
    title: "Experience Magic",
    text: "Enjoy an immersive storytelling experience that keeps you and your child engaged",
  },
];
