export const normalizeText = (text) => {
    return text
      .toLowerCase()
      .replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g,"")
      .replace(/\s{2,}/g," ");
  };
  
  export const containsPhrase = (text, phrases) => {
    const normalizedText = normalizeText(text);
    return phrases.some(phrase => normalizedText.includes(normalizeText(phrase)));
  };

  export const BlogDate = ( createdAt ) => {
    const newDate= new Intl.DateTimeFormat('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    }).format(new Date(createdAt));
    console.log("newDate", newDate);
    return newDate;

    };