import React from "react";
import "./Hero.scss";

export default function Hero() {
  return (
    <section id="hero">
      <div className="hero-bg"></div>
      <div className="container">
        <div className="hero-contents">
          <div className="text-side">
            <img src="assets/img/earth.svg" alt="" />
            <h2>
              Where <span>Your Voice</span> Creates Magical Storytime
            </h2>
            <p>Interactive stories come to life with your voice.</p>
            <button className="primary-btn" onClick={() => window.location.replace("/#download-now")}>Download Now</button>
          </div>

          <div className=" w-fit mx-auto">
            <img src="assets/img/img1.png" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
