import React from "react";
import "./Discover.scss";
import { Flower } from "../../Icon";

export default function Discover({demoClick, isMobile}) {
  return (
    <section id="discover">
      <div className="container">
        <h1>Discover the Magic of Blive</h1>
        <p>Interactive and Easy-to-Use Storytelling for Kids</p>

        <div className="content-wrapper">
          <div className="item item1">
            <h3>
              <Flower />
              Transform Reading Time
              <Flower />
            </h3>

            <p>
              Watch your child develop a love for reading, <br /> improve their
              vocabulary, and enjoy storytime that’s both fun and educational.
            </p>

            <button className="primary-btn" onClick={() => window.location.replace("/#download-now")}>Download Now</button>

            <img
              src="assets/img/device11.png"
              className="img-fluid w-100"
              alt=""
            />
          </div>
          <div className="item item2">
            <img src="assets/img/device2.png" className="img-fluid" alt="" />
            <h3>
              <Flower />
              Don’t Miss Out
              <Flower />
            </h3>
            <p>
              Don't let your child miss out on the joy of reading and the
              educational benefits it brings. Avoid the struggle of keeping them
              engaged with traditional books.
            </p>
            <button className="primary-btn" onClick={demoClick}>{isMobile ? "See How it Works" : "Try Our Demo"}</button>
          </div>
        </div>
      </div>
    </section>
  );
}
