import React from "react";
import "./DownloadNow.scss";
import { Flower40 } from "../../Icon";

export default function DownloadNow() {
  return (
    <section id="download-now">
      <div className="container">
        <div className="content-wrapper">
          <div className="text-side">
            <h1>
              <Flower40 />
              Download Now!
              <Flower40 />
            </h1>
            <p>
              Enjoy our ever-growing stories<br/>
              <span>3 new stories</span> coming every month!
            </p>

            <div className="app-store">
              {/* <a href="/">
                <img
                  className="img-fluid"
                  src="assets/img/google-play.svg"
                  alt=""
                />
              </a> */}
              <a href="https://apps.apple.com/us/app/blive-read-aloud-storytime/id6557028370">
                <img
                  className="img-fluid"
                  src="assets/img/app-store.svg"
                  alt=""
                />
              </a>
            </div>
          </div>

          <div>
            <img
              className="img-fluid"
              src="assets/img/device-group.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}
