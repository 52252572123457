// Components/BlogList/BlogList.js
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import "./BlogList.scss"; // CSS for styling
import { BlogDate } from "../../helper/helper";
import { Helmet } from "react-helmet-async";

const server = "https://blivebackend.onrender.com";
const localServer = "http://localhost:5500";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  console.log("blogs", blogs);
  useEffect(() => {
    axios
      .get(`${server}/blive/api/v1/blogs`)
      .then((response) => {
        console.log("response.data", response.data);
        setBlogs(response.data.blogs);
      })
      .catch((error) => {
        console.error("There was an error fetching the blogs!", error);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Blive Blogs</title>
        <meta
          name="description"
          content="Learn more about the benefit of voice activated stories"
        />
      </Helmet>
      <nav>
        <div className="container">
          <div className="logoblog" onClick={() => navigate("/")}>
            <img src="assets/img/logo.png" alt="" />
          </div>
        </div>
      </nav>
      <div className="container blog-list">
        {blogs.map((blog) => (
          <div key={blog._id} className="blog-item">
            <Link to={`/blog/${blog.slug}`}>
              <img src={blog.image} alt={blog.title} className="blog-image" />
              <h2>{blog.title}</h2>
              <p>{BlogDate(blog.createdAt)}</p>
              <p>{blog.description}</p>
              <p>By {blog.author}</p>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default BlogList;
