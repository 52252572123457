import React from "react";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
export default function Header({demoClick, isMobile}) {
  const navigation = useNavigate();
  return (
    <header id="header">
      <div className="container">
        <div className="content">
          <div className="logo">
            <img src="assets/img/logo.png" alt="" />
          </div>
          <div className="navigation">
          {/* <button className="primary-btn blog-button" onClick={() => navigation('/blog')}>Blogs</button> */}
          <button className="primary-btn" onClick={demoClick}>{isMobile ? "See How it Works" : "Try Our Demo"}</button>
          </div>
        </div>
      </div>
    </header>
  );
}
