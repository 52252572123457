import React from "react";
import "./UsingBlive.scss";

export default function UsingBlive() {
  return (
    <section id="using-blive">
      <div className="container">
        <div className="content-wrapper">
          <div>
            <h3>Using Blive is Easy :</h3>
            <p>
              Transform Storytime into a Magical Adventure with Our Simple,
              Three-Step Process
            </p>

            <div className="boxs-group">
              <div className="box">
                <div
                  className="circle"
                  style={{ backgroundColor: "#295AA3" }}
                ></div>
                <div>
                  <h6>Download the App :</h6>
                  <p className="m-0">
                    Get Blive on your device from the App Store or Google Play.
                  </p>
                </div>
              </div>
              <div className="box">
                <div
                  className="circle"
                  style={{ backgroundColor: "#3A7BDE" }}
                ></div>
                <div>
                  <h6>Select a Story :</h6>
                  <p className="m-0">
                    Choose from a variety of enchanting tales designed to
                    captivate .
                  </p>
                </div>
              </div>
              <div className="box">
                <div
                  className="circle"
                  style={{ backgroundColor: "#83AFF0" }}
                ></div>
                <div>
                  <h6>Start Reading:</h6>
                  <p className="m-0">
                    Speak the words and watch as the story comes to life.
                  </p>
                </div>
              </div>
            </div>
            <a href="/">transform reading time into an adventure.</a>
            <button className="primary-btn" onClick={() => window.location.replace("/#download-now")}>Download Now</button>
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <img
              src="assets/img/img3.png"
              className="img-fluid w-100 "
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}
