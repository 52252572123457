import React from "react";
import "./Struggling.scss";

export default function Struggling({demoClick}) {
  return (
    <section id="struggling">
      <div className="container">
        <div className="content-wrapper">
          <div className="w-fit mx-auto">
            <img src="assets/img/img2.png" className="img-fluid" alt="" />
          </div>

          <div className="d-flex flex-column justify-content-center align-items-start">
            <h3>Create Unforgettable, Startling Storytime for Your Kids</h3>
            <p>
            Blive makes storytime interactive and fun, turning every story into an animated adventure
            </p>
            <button className="primary-btn" onClick={demoClick}>See How It Works</button>
          </div>
        </div>
      </div>
    </section>
  );
}
