import React, { useState } from "react";
import "./Testimonial.scss";
import { ArrowBack } from "../../Icon";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";

export default function Testimonial() {
  const [totalSlides, setTotalSlides] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const swiperRef = React.useRef(null);

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.realIndex);
  };

  const goToNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goToPrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  console.log(currentSlide);

  const testimonials = [
    {
      name: "Priscilla",
      occupation: "Kindergarten teacher",
      content:
        "Blive allows kids to connect verbal story telling with corresponding video. Moreover the story allowed me to control the pace of the story and add my own sound effects to it",
    },
    {
      name: "Selah",
      occupation: "3 years old",
      content:
        "`Go away Fox! Poke Poke, Poke again!` - as her mom reads the story to her and the fox appears (the story is in the demo)",
    },
    {
      name: "Olivia",
      occupation: "Parent",
      content:
        "Blive can encourage the child to read if old enough and the parent to read to the child. Nice for on the go if you don’t have a book with you.",
    },
  ];
  return (
    <section id="testimonial">
      <div className="container">
        <div className="content-wrapper">
          <div className="flex-column d-flex justify-content-center align-items-center ">
            <img className="img-fluid " src="assets/img/img4.png" alt="" />
          </div>

          <div className=" d-flex justify-content-center align-items-center slider-wrapper position-relative">
            <div className="slider-wrapper position-relative">
              <Swiper
                onSlideChange={handleSlideChange}
                modules={[Navigation]}
                spaceBetween={30}
                slidesPerView={1}
                // loop={true}
                ref={swiperRef}
                onSwiper={(swiper) => {
                  setTotalSlides(swiper.slides.length);
                }}
              >
                {testimonials.map((it, i) => (
                  <SwiperSlide key={i}>
                    <div>
                      <div>
                        <img
                          className="qoute-img"
                          src="assets/img/open-qoute.svg"
                          alt=""
                        />
                        <p>{it.content}</p>
                      </div>
                      <div className="d-flex justify-content-end">
                        <img
                          className="qoute-img"
                          src="assets/img/closed-qoute.svg"
                          alt=""
                        />
                      </div>
                      <div className="writter-detail">
                        <div>
                          <h3>{it.name}</h3>
                          <h4>{it.occupation}</h4>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <div className="next-prev-btn-groups">
                <button
                  onClick={goToPrevSlide}
                  className={`${currentSlide > 0 ? "active" : "disabled"}`}
                >
                  <ArrowBack />
                </button>
                <button
                  onClick={goToNextSlide}
                  className={`${
                    currentSlide !== totalSlides - 1 ? "active" : "disabled"
                  }`}
                >
                  <ArrowBack />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
