export const Flower = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="31"
      viewBox="0 0 29 31"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3766 8.73136C26.477 -0.660354 34.3821 13.2316 22.2803 15.4817C34.3821 17.8296 26.3794 31.6237 18.3766 22.2319C22.4755 33.9715 6.56747 33.8738 10.6665 22.2319C2.56606 31.6237 -5.33918 17.8296 6.76266 15.4817C-5.43677 13.2316 2.56606 -0.562526 10.6665 8.73136C6.46988 -2.91045 22.4755 -2.91045 18.3766 8.73136ZM14.4727 13.7207C15.4487 13.7207 16.327 14.5034 16.327 15.4817C16.327 16.4599 15.4487 17.3405 14.4727 17.3405C13.4968 17.3405 12.7159 16.4599 12.7159 15.4817C12.7159 14.5034 13.4968 13.7207 14.4727 13.7207Z"
        fill="#3C6BFF"
      />
    </svg>
  );
};
export const Flower40 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="46"
      viewBox="0 0 40 46"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.5379 13.3305C37.11 -0.0861847 48.403 19.7595 31.1147 22.9738C48.403 26.3281 36.9706 46.0339 25.5379 32.617C31.3936 49.3879 8.66781 49.2483 14.5236 32.617C2.95151 46.0339 -8.34168 26.3281 8.94666 22.9738C-8.4811 19.7595 2.95151 0.0535695 14.5236 13.3305C8.5284 -3.30061 31.3936 -3.30061 25.5379 13.3305ZM19.961 20.4582C21.3553 20.4582 22.61 21.5763 22.61 22.9738C22.61 24.3714 21.3553 25.6293 19.961 25.6293C18.5668 25.6293 17.4513 24.3714 17.4513 22.9738C17.4513 21.5763 18.5668 20.4582 19.961 20.4582Z"
        fill="#3C6BFF"
      />
    </svg>
  );
};

export const ArrowBack = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M8.72095 16.9958C8.55391 16.9958 8.38688 16.9343 8.25501 16.8024L2.91875 11.4662C2.6638 11.2112 2.6638 10.7892 2.91875 10.5343L8.25501 5.19804C8.50996 4.9431 8.93193 4.9431 9.18692 5.19804C9.44187 5.45299 9.44187 5.87497 9.18692 6.12991L4.31655 11.0002L9.18692 15.8706C9.44187 16.1255 9.44187 16.5475 9.18692 16.8024C9.0638 16.9343 8.88798 16.9958 8.72095 16.9958Z"
        fill="black"
      />
      <path
        d="M18.3299 11.6595H3.53434C3.1739 11.6595 2.875 11.3606 2.875 11.0002C2.875 10.6397 3.1739 10.3408 3.53434 10.3408H18.3299C18.6904 10.3408 18.9893 10.6397 18.9893 11.0002C18.9893 11.3606 18.6904 11.6595 18.3299 11.6595Z"
        fill="black"
      />
    </svg>
  );
};
