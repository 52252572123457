import React from "react";
import "./Footer.scss";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="container">
        <div className="content-wrapper">
          <div className="left-side">
            <img src="assets/img/logo.png" alt="" />
            <p>BLive All rights reserved. 2024 ©</p>
          </div>
          <div className="social-media">
            <a href="https://www.facebook.com/profile.php?id=61563194730609" className="item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M18.3332 10.2531C18.3332 5.62265 14.6023 1.8689 9.99984 1.8689C5.39746 1.8689 1.6665 5.62265 1.6665 10.2531C1.6665 14.4379 4.71387 17.9066 8.69775 18.5356V12.6767H6.58187V10.2531H8.69775V8.406C8.69775 6.3047 9.94192 5.144 11.8453 5.144C12.7572 5.144 13.7108 5.30775 13.7108 5.30775V7.37107H12.66C11.6248 7.37107 11.3019 8.01741 11.3019 8.68048V10.2531H13.6131L13.2437 12.6767H11.3019V18.5356C15.2858 17.9066 18.3332 14.4381 18.3332 10.2531Z"
                  fill="white"
                />
              </svg>
            </a>
            <a href="https://www.linkedin.com/company/blive-voice-activated-stories/about/?viewAsMember=true" className="item">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <path
                  d="M0 1.86484C0 1.41439 0.157662 1.04276 0.472973 0.74998C0.788284 0.457183 1.1982 0.310791 1.7027 0.310791C2.1982 0.310791 2.59909 0.454926 2.90541 0.743223C3.22072 1.04052 3.37838 1.4279 3.37838 1.90539C3.37838 2.33782 3.22523 2.69817 2.91892 2.98647C2.60361 3.28376 2.18919 3.43241 1.67568 3.43241H1.66216C1.16666 3.43241 0.76577 3.28376 0.459459 2.98647C0.153149 2.68917 0 2.31529 0 1.86484ZM0.175676 13.6892V4.66214H3.17568V13.6892H0.175676ZM4.83784 13.6892H7.83784V8.64863C7.83784 8.3333 7.87388 8.09006 7.94595 7.9189C8.07207 7.61259 8.26351 7.35357 8.52027 7.14187C8.77703 6.93016 9.09909 6.8243 9.48649 6.8243C10.4955 6.8243 11 7.50448 11 8.86484V13.6892H14V8.51349C14 7.18015 13.6847 6.1689 13.0541 5.47971C12.4234 4.79052 11.5901 4.44593 10.5541 4.44593C9.39189 4.44593 8.48649 4.94593 7.83784 5.94593V5.97295H7.82432L7.83784 5.94593V4.66214H4.83784C4.85585 4.95043 4.86486 5.84682 4.86486 7.35133C4.86486 8.85583 4.85585 10.9684 4.83784 13.6892Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
