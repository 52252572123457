    export const story = [
      {
        text: "Once upon a time there was a hungry fox looking for food.",
        leniency: ["looking for food", "fox looking for food"],
        part: { "start": 0.0, "end": 10.0 },
        audioPart: { "start": 2.6, "end": 10.7 }
      },
      {
        text: "He walked and walked until he saw a large, juicy grape hanging over a fence.",
        leniency: ["hanging over a fence", "over a fence"],
        part: { "start": 10.0, "end": 20.0 },
        audioPart: { "start": 11.0, "end": 16.4 }
      },
      {
        text: "Fox jumped, leaped and tried to climb the fence, but the grape was too high up.",
        leniency: ["the grape was too high up", "too high up"],
        part: { "start": 20.0, "end": 34.0 },
        audioPart: { "start": 17.0, "end": 23.7 }
      },
      {
        text: "He carefully walked around the fence and saw a small hole just big enough for him.",
        leniency: ["just big enough for him", "enough for him"],
        part: { "start": 34.0, "end":43.0},
        audioPart: { "start": 23.5, "end": 30.9 }
      },
      {
        text: "He squeezed himself in and pushed out to the other side.",
        leniency: ["pushed out to the other side", "to the other side"],
        part: { "start": 43.0, "end": 50.0 },
        audioPart: { "start": 31.0, "end": 36.7 }
      }
    ]
