import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Discover from "./Components/Discover/Discover";
import DownloadNow from "./Components/DownloadNow/DownloadNow";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Hero from "./Components/Hero/Hero";
import InteractiveStory from "./Components/InteractiveStory/InteractiveStory";
import Struggling from "./Components/Struggling/Struggling";
import Testimonial from "./Components/Testimonial/Testimonial";
import UsingBlive from "./Components/UsingBlive/UsingBlive";
import DemoPopup from "./Components/DemoPopup/DemoPopup";
import Demo from "./Components/Demo/Demo";
import BlogList from "./Components/BlogList/BlogList";
import BlogPost from "./Components/BlogPost/BlogPost";
import { Helmet } from "react-helmet-async";
import EmailPopup from "./Components/EmailPopup/EmailPopup";
import axios from "axios";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/demo" element={<Demo />} />
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/demo-popup" element={<DemoPopup />} />
      </Routes>
    </Router>
  );
}

function HomePage() {
  const [isMobile, setIsMobile] = useState(false);
  // const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Detect if the user is on a mobile device
    const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
    setIsMobile(isMobileDevice);

    // const handleScroll = () => {
    //   const scrollPosition = window.scrollY + window.innerHeight;
    //   const pageHeight = document.documentElement.scrollHeight;

    //   // Trigger popup after 1/3rd scroll
    //   if (scrollPosition >= pageHeight / 2) {
    //     setShowPopup(true);
    //     window.removeEventListener("scroll", handleScroll); // Show only once
    //   }
    // };

    // window.addEventListener("scroll", handleScroll);
    // return () => {
    //   window.removeEventListener("scroll", handleScroll);
    // };
  }, []);

  const handleSubmit = async (email, setError) => {
    try {
      const response = await axios.post(
        "https://blivebackend.onrender.com/blive/api/v1/demo/confirmation",
        { agreed: true, email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.success) {
        console.log("response.data", response.data);
        return response.data.code; // Return success flag
      }
    } catch (error) {
      console.error("Submission error:", error);
      setError(error.response.data.message);
      return false;
    }
  };

  // const closePopup = () => {
  //   setShowPopup(false);
  // };

  const navigate = useNavigate();

  const goToDemo = () => {
    if (isMobile) {
      window.location.href = "https://www.youtube.com/watch?v=0L-wtK4yu_k";
    } else {
      navigate("/demo");
    }
  };
  return (
    <>
      <Helmet>
        <title>Blive: Magical Stories for Kids</title>
        <meta name="description" content="Experience the magic in storytime by bring your voice alive through animated stories" />
      </Helmet>
      <Header demoClick={goToDemo} isMobile={isMobile} />
      <main>
        <Hero />
        <Struggling demoClick={goToDemo} />
        <InteractiveStory />
        {/* {showPopup && (
          <EmailPopup onClose={closePopup} onSubmit={handleSubmit} />
        )} */}
        <UsingBlive />
        <Testimonial />
        <Discover demoClick={goToDemo} isMobile={isMobile} />
        <DownloadNow />
      </main>
      <Footer />
    </>
  );
}

export default App;
