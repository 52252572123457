import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import "./BlogPost.scss"; // CSS for styling
import { BlogDate } from "../../helper/helper";

const server = "https://blivebackend.onrender.com";
const localServer = "http://localhost:5500";
const BlogPost = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${server}/blive/api/v1/blogs/${slug}`)
      .then((response) => {
        setBlog(response.data.blog);
      })
      .catch((error) => {
        console.error("There was an error fetching the blog post!", error);
      });
  }, [slug]);

  if (!blog) {
    return <p>Loading...</p>;
  }

  function formatBody(text) {
    const boldRegex = /\*\*([^\*]+)\*\*/g;
    const linkRegex = /\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g;
    const lines = text.split("\n");
    
    return lines.map((line, lineIndex) => {
      let parts = [];
      let lastIndex = 0;
      let match;

      while ((match = linkRegex.exec(line)) !== null) {
        if (match.index > lastIndex) {
          parts.push(line.substring(lastIndex, match.index));
        }

        parts.push(
          <a key={match.index} href={match[2]} target="_blank" rel="noopener noreferrer">
            {match[1]}
          </a>
        );

        lastIndex = linkRegex.lastIndex;
      }

      if (lastIndex < line.length) {
        parts.push(line.substring(lastIndex));
      }

      parts = parts.map((part, index) => {
        if (typeof part === 'string') {
          const boldParts = [];
          let boldLastIndex = 0;
          let boldMatch;

          while ((boldMatch = boldRegex.exec(part)) !== null) {
            if (boldMatch.index > boldLastIndex) {
              boldParts.push(part.substring(boldLastIndex, boldMatch.index));
            }

            boldParts.push(<strong key={index + '-' + boldMatch.index}>{boldMatch[1]}</strong>);

            boldLastIndex = boldRegex.lastIndex;
          }

          if (boldLastIndex < part.length) {
            boldParts.push(part.substring(boldLastIndex));
          }

          return <span key={index}>{boldParts}</span>;
        }
        return part;
      });

      return <p key={lineIndex}>{parts}</p>;
    });
  }

  return (
    <div className="blog-post">
      <Helmet>
        <title>{blog.title} - Blog</title>
        <meta name="description" content={blog.description || blog.intro} />
        <meta property="og:title" content={blog.title} />
        <meta property="og:description" content={blog.description || blog.intro} />
        <meta property="og:image" content={blog.image} />
      </Helmet>
      <div className="logoblog">
        <div onClick={() => navigate("/")}>
          <img src="../../../assets/img/logo.png" alt="Logo" />
        </div>
      </div>
      <img src={blog.image} alt={blog.title} className="blog-post-image" />
      <h1>{blog.title}</h1>
      <p>By {blog.author}</p>
      <p>{BlogDate(blog.createdAt)}</p>
      <p>{blog.intro}</p>
      {blog.sections.map((section, index) => (
        <div key={index}>
          <h3>{section.header}</h3>
          <div className="blog-section">
            {formatBody(section.body)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default BlogPost;
