import React, { useState } from 'react';
import './DemoPopup.scss';
import axios from "axios";
import { useNavigate } from 'react-router-dom';

const DemoPopup = ({close}) => {  // Accept an onClose prop
  const [email, setEmail] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "http://192.168.1.39:5500/blive/api/v1/demo/confirmation",
        { agreed, email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (email && response.data.success === true) {
        navigate("/demo");
      }
    } catch (error) {
      console.error("handleSubmit error:", error);
      setError("Double check if the email is correct");
    }
  };

  const handleCancel = (e) => {
    e.preventDefault(); // Prevent the form from being submitted
    close() // Navigate back to /demo
  };

  
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <form onSubmit={handleSubmit}>
          <h4>Keep in Touch!</h4>
          <input
            type="email"
            value={email}
            placeholder='youremail@email.com'
            onChange={(e) => {
              setEmail(e.target.value);
              setError(null);
            }}
            required
          />
          <p className='error-message'>{error}</p>

          <div className="checkbox-container">
            <input
              type="checkbox"
              checked={agreed}
              onChange={() => setAgreed(!agreed)}
            />
            <span>
              I want to receive helpful info and future deals from Blive
            </span>
          </div>
          <div className='demo-buttons'>
          <button type="cancel" className="cancel-button" onClick={handleCancel}>Cancel</button>
          <button type="submit" className="submit-button">Submit</button>
          </div>
          <p className='legals'><a href="https://beyouc.github.io/blivetnt/"> Terms and Conditions</a> and <a href="https://beyouc.github.io/bliveprivacypolicy/"> Privacy Policy</a></p>
        </form>
      </div>
    </div>
  );
};

export default DemoPopup;
